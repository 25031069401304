import '@northone/ui-components'
import { Tooltip } from '@northone/ui-components'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { OwnerProfileForm } from './owner-profiles/OwnerProfileForm'
import { useOwnerProfileValidation } from './owner-profiles/utils/owner-validation-hooks'

export default function OwnershipPrimaryOwner() {
  const t = useOnboardingTranslations()
  const navigate = useNavigate()
  const { isSoleProp } = useBusinessType()

  const nextPage = useMemo(() => {
    if (!isSoleProp) return Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS
    return Pathname.APPLICATION_SUBMIT
  }, [isSoleProp])

  const heading = t('ownership.profile.primaryOwnerHeading')
  const tooltipTitle = t('tooltip.whyDoWeCollectThisInformation')
  const tooltipTitleContent = t('ownership.profile.tooltipWhyCollectPersonalInfoContent')

  const { hasError, showErrors, setShowErrors } = useOwnerProfileValidation({
    ownerId: PRIMARY_OWNER_ID,
  })

  const onContinue = () => {
    setShowErrors(true)
    if (hasError) return
    navigate(nextPage)
  }
  const onBack = () => {
    navigate(Pathname.OWNERSHIP_DETAILS)
  }

  const primaryButton = <ContinueButton testID="owner-profiles-primary-owner-continue" onPress={onContinue} fullWidth />
  const secondaryButton = <GoBackButton testID="owner-profiles-primary-owner-back" onPress={onBack} fullWidth />

  const TooltipSubheading = () => (
    <Tooltip content={tooltipTitleContent} title={tooltipTitle}>
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  return (
    <BaseContentLayout
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      headingText={heading}
      tooltipSubheading={<TooltipSubheading />}
      titleZIndex={3}
    >
      <OwnerProfileForm ownerId={PRIMARY_OWNER_ID} shouldShowValidationErrors={showErrors} />
    </BaseContentLayout>
  )
}
