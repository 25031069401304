import { ApolloProvider } from '@apollo/client'
import Segment from '@northone/segment-js'
import { GluestackUIProvider } from '@northone/ui-components'
import { config as themeConfig } from '@northone/ui-theme'
import * as Sentry from '@sentry/react'
import '@northone/ui-theme/styles/typography.css'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '@/assets/main.css'
import { App } from './App'
import { client } from './core/apollo/apollo-client'
import { configureStore } from './core/redux/store'
import { ApplicationSubmit } from './features/onboarding-application/application-submit/application-submit'
import { OnboardingStatusWrapper } from './features/onboarding-status-wrapper/onboarding-status-wrapper'
import { GlobalErrorCatch } from './global-error-catch'
import * as serviceWorker from './serviceWorker'
import './i18n/i18n'
import { isMobile } from 'react-device-detect'
import { analytics } from './core/analytics/events'
import { AccountUsageLanding } from './routes/account-usage/AccountUsageLanding'
import { AccountUsageRoot } from './routes/account-usage/AccountUsageRoot'
import { AverageDeposit } from './routes/account-usage/AverageDeposit'
import { DebitCardAmount } from './routes/account-usage/DebitCardAmount'
import MonthlyACHPayments from './routes/account-usage/MonthlyACHPayments'
import MonthlyCheckDeposits from './routes/account-usage/MonthlyCheckDeposits'
import MonthlyWirePayments from './routes/account-usage/MonthlyWirePayments'
import BusinessDetailsAboutYourBusiness from './routes/business-details/BusinessDetailsAboutYourBusiness'
import BusinessDetailsContactInfo from './routes/business-details/BusinessDetailsContactInfo'
import BusinessDetailsDescription from './routes/business-details/BusinessDetailsDescriptionAndIndustry'
import BusinessDetailsExpectedRevenue from './routes/business-details/BusinessDetailsExpectedRevenue'
import BusinessDetailsLanding from './routes/business-details/BusinessDetailsLanding'
import BusinessDetailsOnlinePresence from './routes/business-details/BusinessDetailsOnlinePresence'
import BusinessDetailsRoot from './routes/business-details/BusinessDetailsRoot'
import { Pathname } from './routes/constants'
import { DocumentUploadBusinessLicense } from './routes/document-upload/DocumentUploadBusinessLicense'
import { DocumentUploadProofOfIdentityBack } from './routes/document-upload/DocumentUploadProofOfIdentityBack'
import { DocumentUploadProofOfIdentityFront } from './routes/document-upload/DocumentUploadProofOfIdentityFront'
import { DocumentUploadProofOfOwnership } from './routes/document-upload/DocumentUploadProofOfOwnership'
import { DocumentUploadThankYou } from './routes/document-upload/DocumentUploadThankYou'
import { FileCaptureRoot } from './routes/finish-up/FileCaptureRoot'
import FinishUpLanding from './routes/finish-up/FinishUpLanding'
import { FinishUpRoot } from './routes/finish-up/FinishUpRoot'
import { InAppDocumentCaptureThankYou } from './routes/finish-up/in-app-document-capture-screens/thank-you'
import { MailingAddressScreen } from './routes/finish-up/mailing-address/MailingAddressScreen'
import { ManualReviewInAppDocumentCapture } from './routes/finish-up/manual-review-in-app-document-capture'
import { OnboardingApplicationStatus } from './routes/finish-up/onboarding-application-status'
import { WelcomeScreen } from './routes/finish-up/welcome-screen'
import { RequireApprovedApplication } from './routes/guards/require-approved-application'
import { RequireExistingOwnerId } from './routes/guards/require-existing-owner-id'
import { RequireNonDeclinedApplication } from './routes/guards/require-non-declined-application'
import { AdditionalOwnerLayout } from './routes/join-team/AdditionalOwnerLayout'
import { JoinTeamCelebration } from './routes/join-team/JoinTeamCelebration'
import { ShippingAddressScreen } from './routes/join-team/shipping-address-screen'
import { OwnershipAddOwner } from './routes/ownership/owner-profiles/OwnershipAddOwner'
import { OwnershipEditOwner } from './routes/ownership/owner-profiles/OwnershipEditOwner'
import OwnershipLanding from './routes/ownership/owner-profiles/OwnershipLanding/OwnershipLanding'
import OwnershipOwnerProfiles from './routes/ownership/owner-profiles/OwnershipOwnerProfiles'
import OwnershipController from './routes/ownership/OwnershipController'
import OwnershipPrimaryOwner from './routes/ownership/OwnershipPrimaryOwner'
import { OwnershipReviewOwners } from './routes/ownership/OwnershipReviewOwners'
import OwnershipRoot from './routes/ownership/OwnershipRoot'
import { RejectedScreen } from './routes/rejected-screen/RejectedScreen'
import { EmailVerify } from './routes/welcome/email-verify'
import GettingStarted from './routes/welcome/getting-started/GettingStarted'
import { Qualifiers } from './routes/welcome/Qualifiers'
import { WelcomeBusinessType } from './routes/welcome/WelcomeBusinessType'
import { WelcomeRoot } from './routes/welcome/WelcomeRoot'
import WelcomeTermsAndConditions from './routes/welcome/WelcomeTermsAndConditions'
import { config, RELEASE_VERSION } from './utils/environment'

// Fix "Uncaught ReferenceError: global is not defined" error in Vite builds
window.global ||= window // eslint-disable-line @typescript-eslint/no-unnecessary-condition

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.stage,
  integrations: [new BrowserTracing()],
  ...(RELEASE_VERSION && { release: RELEASE_VERSION }),
})

const handleWindowClose = () => analytics.session.end({ deviceType: isMobile ? 'mobile' : 'desktop' })
window.addEventListener('beforeunload', handleWindowClose)

export const { store } = configureStore()

if (config.segmentWriteKey) {
  Segment.load(config.segmentWriteKey)
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('#root element not found in index.html')

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <>
      <GlobalErrorCatch>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <GluestackUIProvider config={themeConfig}>
              <BrowserRouter>
                <Routes>
                  <Route path={Pathname.REJECTED} element={<RejectedScreen />} />

                  <Route path={Pathname.ROOT} element={<App />}>
                    <Route path="*" element={<OnboardingStatusWrapper />} />
                    <Route index element={<OnboardingStatusWrapper />} />

                    <Route path={Pathname.WELCOME_GETTING_STARTED} element={<WelcomeRoot />}>
                      <Route index element={<GettingStarted />} />
                      <Route path={Pathname.WELCOME_EMAIL_VERIFY} element={<EmailVerify />} />
                      <Route path={Pathname.WELCOME_BUSINESS_TYPE} element={<WelcomeBusinessType />} />
                      <Route path={Pathname.WELCOME_QUALIFIERS} element={<Qualifiers />} />
                      <Route
                        path={Pathname.WELCOME_TERMS_AND_CONDITIONS}
                        element={<WelcomeTermsAndConditions nextPage={Pathname.BUSINESS_DETAILS} />}
                      />
                    </Route>

                    <Route
                      path={Pathname.BUSINESS_DETAILS}
                      element={
                        <RequireNonDeclinedApplication>
                          <BusinessDetailsRoot />
                        </RequireNonDeclinedApplication>
                      }
                    >
                      <Route index element={<BusinessDetailsLanding />} />
                      <Route
                        path={Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS}
                        element={<BusinessDetailsAboutYourBusiness />}
                      />
                      <Route
                        path={Pathname.BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY}
                        element={<BusinessDetailsDescription />}
                      />
                      <Route path={Pathname.BUSINESS_DETAILS_CONTACT_INFO} element={<BusinessDetailsContactInfo />} />
                      <Route
                        path={Pathname.BUSINESS_DETAILS_EXPECTED_REVENUE}
                        element={<BusinessDetailsExpectedRevenue />}
                      />
                      <Route
                        path={Pathname.BUSINESS_DETAILS_ONLINE_PRESENCE}
                        element={<BusinessDetailsOnlinePresence />}
                      />
                    </Route>

                    <Route path={Pathname.OWNERSHIP_DETAILS} element={<OwnershipRoot />}>
                      <Route index element={<OwnershipLanding />} />
                      <Route path={Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES} element={<OwnershipOwnerProfiles />} />
                      <Route path={Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS} element={<OwnershipReviewOwners />} />
                      <Route path={Pathname.OWNERSHIP_DETAILS_CONTROLLER} element={<OwnershipController />} />
                      <Route path={Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER} element={<OwnershipPrimaryOwner />} />
                      <Route
                        path={Pathname.OWNERSHIP_DETAILS_EDIT_OWNER}
                        element={
                          <RequireExistingOwnerId>
                            <OwnershipEditOwner />
                          </RequireExistingOwnerId>
                        }
                      />
                      <Route
                        path={Pathname.OWNERSHIP_DETAILS_ADD_OWNER}
                        element={
                          <RequireExistingOwnerId>
                            <OwnershipAddOwner />
                          </RequireExistingOwnerId>
                        }
                      />
                    </Route>

                    <Route path={Pathname.USAGE_QUESTIONS} element={<AccountUsageRoot />}>
                      <Route index element={<AccountUsageLanding />} />
                      <Route path={Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT} element={<DebitCardAmount />} />
                      <Route path={Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT} element={<AverageDeposit />} />
                      <Route path={Pathname.USAGE_QUESTION_CHECK_DEPOSITS} element={<MonthlyCheckDeposits />} />
                      <Route path={Pathname.USAGE_QUESTION_ACH_PAYMENTS} element={<MonthlyACHPayments />} />
                      <Route path={Pathname.USAGE_QUESTION_WIRE_PAYMENTS} element={<MonthlyWirePayments />} />
                    </Route>

                    <Route
                      path={Pathname.APPLICATION_SUBMIT}
                      element={
                        <RequireNonDeclinedApplication>
                          <ApplicationSubmit />
                        </RequireNonDeclinedApplication>
                      }
                    />

                    <Route path={Pathname.APPLICATION_STATUS} element={<OnboardingApplicationStatus />} />

                    <Route path={Pathname.DOCUMENT_UPLOAD} element={<FileCaptureRoot />}>
                      <Route path={Pathname.DOCUMENT_UPLOAD_FORM} element={<ManualReviewInAppDocumentCapture />} />
                      <Route path={Pathname.DOCUMENT_UPLOAD_THANK_YOU} element={<InAppDocumentCaptureThankYou />} />
                    </Route>

                    <Route path={Pathname.FINISH_UP} element={<FinishUpRoot />}>
                      <Route index element={<FinishUpLanding />} />
                      <Route path={Pathname.DEBIT_CARD_DELIVERY_ADDRESS} element={<MailingAddressScreen />} />
                      <Route
                        path={Pathname.FINISH_UP_WELCOME_SCREEN}
                        element={
                          <RequireApprovedApplication>
                            <WelcomeScreen />
                          </RequireApprovedApplication>
                        }
                      />
                      <Route path={Pathname.FINISH_UP_FILE_UPLOAD} element={<FileCaptureRoot />}>
                        <Route
                          path={Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT}
                          element={<DocumentUploadProofOfIdentityFront />}
                        />
                        <Route
                          path={Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK}
                          element={<DocumentUploadProofOfIdentityBack />}
                        />
                        <Route
                          path={Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE}
                          element={<DocumentUploadBusinessLicense />}
                        />
                        <Route
                          path={Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP}
                          element={<DocumentUploadProofOfOwnership />}
                        />
                        <Route path={Pathname.FINISH_UP_FILE_UPLOAD_THANK_YOU} element={<DocumentUploadThankYou />} />
                      </Route>
                    </Route>

                    <Route path={Pathname.JOIN_TEAM} element={<AdditionalOwnerLayout />}>
                      <Route index element={<ShippingAddressScreen />} />
                      <Route
                        path={Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS}
                        element={<WelcomeTermsAndConditions nextPage={Pathname.JOIN_TEAM_CELEBRATION} />}
                      />
                      <Route path={Pathname.JOIN_TEAM_CELEBRATION} element={<JoinTeamCelebration />} />
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </GluestackUIProvider>
          </Provider>
        </ApolloProvider>
      </GlobalErrorCatch>
    </>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
